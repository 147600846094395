import React from 'react';

const InsertSelect = () => {
    return (
      <select className="aside__container aside__select aside__select--insert" id="insert-select">
        <option value="picture">Рисунок</option>
      </select>
    );
  };
  
export default InsertSelect;  