import React from "react";

const FontSelector = ({ searchTerm, setSearchTerm, fontFamily, fontSize, filteredFonts, handleFontChange, handleFontSizeChange }) => {
  return (
    <div>
      <div className="container--block">
        <input
          type="text"
          placeholder="Поиск шрифта..."
          className="aside__select aside__select--search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="aside__container fix--margin">
        <select
          id="font-select"
          className="aside__select aside__select--font"
          value={fontFamily}
          onChange={handleFontChange}
        >
          {filteredFonts.map((font) => (
            <option key={font} value={font}>
              {font}
            </option>
          ))}
        </select>
        <select
          id="size-select"
          className="aside__select aside__select--size"
          value={fontSize}
          onChange={handleFontSizeChange}
        >
          <option value="14">14</option>
          <option value="16">16</option>
          <option value="18">18</option>
          <option value="20">20</option>
        </select>
      </div>
    </div>
  );
};

export default FontSelector;
