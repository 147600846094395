import "../../scss/custom/Details.scss";

const Details = ({ children, title }) => {
    return (
        <details className="details">
            <summary className="details__summary">{ title }</summary>
            { children }
        </details>
    )
}

export default Details


