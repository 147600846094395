import '../scss/custom/Main.scss'
import DocumentsPage from './Documents'

const MainContent = (bool) => {
    return (
        <div className='main'>
            {bool 
            ? 
            <DocumentsPage/>
            : 
            <div></div>}
        </div>
    )
}

export default MainContent