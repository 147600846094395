import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../scss/custom/auth.scss";
import { ReactComponent as IconLogo } from "../assets/icons/iconLogo.svg";
import Button from "./basic/Button";

const PasswordReset = () => {
  const [login, setLogin] = useState("");
  const navigate = useNavigate();

  const handlePasswordReset = (e) => {
    e.preventDefault();
    // Заглушка для отправки кода на восстановление пароля
    console.log("Восстановление пароля для:", login);
    // Навигация после успешного запроса восстановления
    navigate('/password-reset-confirmation');
  };

  return (
    <section className="auth">
      <div className="auth__container">
        {/* Логотип */}
        <IconLogo className="auth__logo" />

        <div className="auth__content-wrapper">
          <div className="auth__title-wrapper">
            <h1>Восстановление пароля</h1>
            <a href="/" className="auth__link">
              Вернуться на главную
            </a>
          </div>

          {/* Форма восстановления пароля */}
          <form className="auth__form" onSubmit={handlePasswordReset}>
            {/* Логин */}
            <label>
              Логин
              <input
                type="text"
                name="login"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                placeholder="Почта или юзернейм"
                className="auth__input"
                autoComplete="off"
              />
            </label>

            {/* Кнопка восстановить пароль */}
            <Button type="submit" modifier={ ' button--primary mt-10' }>
              Отправить код
            </Button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default PasswordReset;