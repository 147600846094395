import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../scss/custom/auth.scss";
import { ReactComponent as IconLogo } from "../assets/icons/iconLogo.svg";
import { ReactComponent as IconPasswordShow } from "../assets/icons/iconPasswordShow.svg";
import Button from "./basic/Button";

const Signup = () => {
  const [showPass, setShowPass] = useState(false);
  const [state, setState] = useState({
    name: "",
    password: "",
    email: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    console.log(state)
    try {
      const response = await fetch(
        "https://gostdocapi.computernetthings.ru/user/signup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(state),
        }
      );

      if (!response.ok) {
        const errorText = response;
        console.log(errorText);
        return;
      }

      const data = await response.json();
      console.log("Регистрация успешна! ", data);
      navigate("/education");
    } catch (error) {
      console.error("Ошибка! ", error);
    }
  };

  return (
    <section className="auth">
      <div className="auth__container">
        {/* Логотип */}
        <IconLogo className="auth__logo" />

        <div className="auth__content-wrapper">
          <div className="auth__title-wrapper">
            <h1 className="auth__title">Регистрация</h1>
            <a href="/login" className="auth__link">
              Есть аккаунт? Войти
            </a>
          </div>

          {/* Форма регистрации */}
          <form className="auth__form" onSubmit={handleRegister}>
            {/* Почта */}
            <label className="auth__label">
              Почта
              <input
                type="email"
                name="email"
                placeholder="example@example.com"
                className="auth__input"
                autoComplete="off"
                onChange={handleChange}
              />
            </label>

            {/* Имя пользователя */}
            <label className="auth__label">
              Имя пользователя
              <input
                type="text"
                name="name"
                placeholder="Введите уникальное имя пользователя"
                className="auth__input"
                autoComplete="off"
                onChange={handleChange}
              />
            </label>

            {/* Пароль */}
            <label className="auth__label">
              Пароль
              <div className="auth__input-wrapper">
                <input
                  type={showPass ? "text" : "password"}
                  name="password"
                  placeholder="Ваш пароль"
                  className="auth__input auth__input--with-icon"
                  autoComplete="off"
                  onChange={handleChange}
                />
                <IconPasswordShow
                  className="auth__icon"
                  onClick={() => setShowPass(!showPass)}
                />
              </div>
            </label>

            {/* Кнопка зарегистрироваться */}
            <Button type="sumbit" modifier={" button--primary mt-10"}>
              Зарегистрироваться
            </Button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Signup;
