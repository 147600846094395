import { useState } from "react";

export const useTextFormatting = () => {
  const [fontWeight, setFontWeight] = useState(400);
  const [fontStyle, setFontStyle] = useState("normal");
  const [textDecoration, setTextDecoration] = useState("none");

  const handleBoldClick = () => {
    const selection = window.getSelection();
    
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const selectedText = range.toString();
  
      if (selectedText) {
        const parentElement = range.startContainer.parentElement;
        const currentFontWeight = window.getComputedStyle(parentElement).fontWeight;
        const newFontWeight = currentFontWeight === "bold" || fontWeight === 700 ? "normal" : "bold";
        document.execCommand('bold', false, null); 
  
        setFontWeight(newFontWeight === "bold" ? 700 : 400);
      }
    }
  };
  
  const handleItalicClick = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const selectedText = range.toString();
      
      if (selectedText) {
        const span = document.createElement("span");
        span.textContent = selectedText;
  
        const parentElement = range.startContainer.parentElement;

        if (parentElement) {
          const currentFontStyle = window.getComputedStyle(parentElement).fontStyle;
          const newFontStyle = currentFontStyle === "italic" ? "normal" : "italic";
  
          span.style.fontStyle = newFontStyle;
  
          range.deleteContents();
          range.insertNode(span);
          range.collapse(false);
  
          setFontStyle(newFontStyle);
        }
      }
    }
  };
  
  const handleUnderlineClick = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const selectedText = range.toString();
      
      if (selectedText) {
        const span = document.createElement("span");
        span.textContent = selectedText;
  
        const parentElement = range.startContainer.parentElement;
  
        if (parentElement) {
          const currentTextDecoration = window.getComputedStyle(parentElement).textDecoration;
          const newTextDecoration = currentTextDecoration === "underline" ? "none" : "underline";
          span.style.textDecoration = newTextDecoration;
  
          range.deleteContents();
          range.insertNode(span);
          range.collapse(false);
  
          setTextDecoration(newTextDecoration);
        }
      }
    }
  };

  return {
    fontWeight,
    fontStyle,
    textDecoration,
    handleBoldClick,
    handleItalicClick,
    handleUnderlineClick,
  };
};


