import React from 'react';

const HiddenSymbolsToggle = () => {
    return (
      <div className="aside__container">
        <p>Скрыть</p>
        <label className="aside__switch">
          <input type="checkbox" id="on" className="aside__toggle" />
          <span className="aside__slider"></span>
        </label>
        <p>Показать</p>
      </div>
    );
  };
  
  export default HiddenSymbolsToggle;
  