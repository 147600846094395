import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../scss/custom/auth.scss";
import { ReactComponent as IconLogo } from "../assets/icons/iconLogo.svg";
import Button from "./basic/Button";

const PasswordConfirmationCode = () => {
  const [ConfirmationCode, setConfirmationCode] = useState("");
  const navigate = useNavigate();

  const [timer, setTimer] = useState(50); // Начальное значение таймера
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const handleConfirmation = (e) => {
    e.preventDefault();
    // Заглушка для проверки кода подтверждения
    console.log("Код подтверждения:", ConfirmationCode);
    // Навигация после успешного ввода кода
    navigate('/password-new');
  };

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      
      return () => clearInterval(countdown);
    } else {
      setIsButtonDisabled(false);
    }
  }, [timer]);

  const handleResendCode = () => {
    setTimer(50); // Сброс таймера
    setIsButtonDisabled(true); // Сделать кнопку снова неактивной
    console.log("Код отправлен повторно");
  };

  return (
    <section className="auth">
      <div className="auth__container">
        {/* Логотип */}
        <IconLogo className="auth__logo" />

        <div className="auth__content-wrapper">
          <div className="auth__title-wrapper">
            <h1>Код подтверждения</h1>
            <a href="/" className="auth__link">
              Вернуться на главную
            </a>
          </div>

          {/* Форма ввода кода подтверждения */}
          <form className="auth__form" onSubmit={handleConfirmation}>
            {/* Поле для ввода кода подтверждения */}
            <label>
              Введите код подтверждения
              <input
                type="text"
                name="ConfirmationCode"
                value={ConfirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                placeholder="Код будет отправлен на почту"
                className="auth__input"
                autoComplete="off"
              />
            </label>

            {/* Кнопка "Продолжить" */}
            <Button type="submit" modifier={' button--primary mt-10'}>
              Продолжить
            </Button>

            {/* Кнопка "Отправить код повторно" */}
            <Button type="button" modifier={' button--secondary mt-5 ' } onClick={handleResendCode} disabled={isButtonDisabled}>
              Отправить код повторно
            </Button>

            {/* Сообщение о времени до повторной отправки */}
            <p className="auth__note">
              {isButtonDisabled ? `Вы сможете отправить код повторно через ${timer} сек` : 'Вы можете отправить код повторно'}
            </p>
          </form>
        </div>
      </div>
    </section>
  );
};

export default PasswordConfirmationCode;