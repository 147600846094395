import '../../scss/custom/Button.scss';

const Button = ({ children, modifier, onClick, disabled }) => {
    const className = `button ${modifier}`;

    return (
        <button className={className} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    );
};

export default Button;