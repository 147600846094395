import React from 'react';
import Button from "../../basic/Button";
import iconOneDoc from '../../../assets/icons/iconOneDoc.svg';
import iconTwoDoc from '../../../assets/icons/iconTwoDoc.svg';
import iconManyDoc from '../../../assets/icons/iconManyDoc.svg';

const DocumentViewButtons = () => {
  return (
    <ul className="aside__container">
      <li>
        <Button modifier=" button--icon">
          <img src={iconOneDoc} alt="One Doc" />
        </Button>
      </li>
      <li>
        <Button modifier=" button--icon">
          <img src={iconTwoDoc} alt="Two Doc" />
        </Button>
      </li>
      <li>
        <Button modifier=" button--icon">
          <img src={iconManyDoc} alt="Many Doc" />
        </Button>
      </li>
    </ul>
  );
};

export default DocumentViewButtons;
