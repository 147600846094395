import React from 'react';

const PageCounter = ({ wordCount }) => {
    return (
      <div className="aside__counter">
        <div className="counter__wrapper">
          <span>Номер страницы</span>
          <span className="num--page">3</span>
        </div>
        <div className="counter__wrapper">
          <span>Количество слов</span>
          <span className="count--word">{wordCount}</span>
        </div>
      </div>
    );
  };
  
  export default PageCounter;  