import "../../scss/custom/Sidebar.scss";
import Details from "../basic/Details";

const EditorRightSidebar = () => {
  return (
    <aside className="aside aside--editor">
      <>
        <Details title={ 'Сохранение' }>
          <ul></ul>
        </Details>
        <Details title={ 'Печать' }>
          <ul></ul>
        </Details>
        <Details title={ 'Параметры документа' }>
          <ul></ul>
        </Details>
      </>
    </aside>
  );
};

export default EditorRightSidebar;
