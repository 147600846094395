import React from 'react';
import Button from "../../basic/Button";
import iconOrdered from '../../../assets/icons/iconOrdered.svg';
import iconUnordered from '../../../assets/icons/iconUnordered.svg';

const ListFormattingButtons = () => {
  return (
    <ul className="aside__container">
      <li>
        <Button modifier=" button--icon">
          <img src={iconOrdered} alt="Ordered List" />
        </Button>
      </li>
      <li>
        <Button modifier=" button--icon">
          <img src={iconUnordered} alt="Unordered List" />
        </Button>
      </li>
    </ul>
  );
};

export default ListFormattingButtons;
