import { useRef } from "react";
import "../../scss/custom/Editor.scss";

const Editor = ({
  fontFamily,
  fontSize,
  onWordCountChange,
  fontWeight,
  textDecoration,
  fontStyle,
}) => {
  const editorRef = useRef(null);

  // Обработчик изменения текста
  const handleTextChange = () => {
    const newText = editorRef.current.innerText; 
    onWordCountChange(newText.trim().split(/\s+/).length || 0); 
  };

  return (
    <div>
      <div
        className="document"
        contentEditable={true}
        ref={editorRef}
        style={{
          fontFamily: fontFamily,
          fontSize: `${fontSize}px`,
          fontWeight: fontWeight,
          fontStyle: fontStyle,
          textDecoration: textDecoration,
          direction: "ltr",
          whiteSpace: "pre-wrap",
          color: "black",
        }}
        onInput={handleTextChange}
      />
    </div>
  );
};

export default Editor;
