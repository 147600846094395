import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import MainContent from "../components/MainContent";

const MainLayout = () => {
  
  return (
    <div className="layout">
      <Header/>

      <main>
        <Sidebar />
        <MainContent />
      </main>

      {/* <footer>
        <Footer />
      </footer> */}
    </div>
  );
};

export default MainLayout;
