import WebFont from "webfontloader";
import { useState, useEffect } from 'react';


export const useFonts = (onFontChange) => {
    const [fontFamily, setFontFamily] = useState("Roboto");
    const [fontSize, setFontSize] = useState(14);
    const [fonts, setFonts] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
  
    useEffect(() => {
      fetch("https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyC0siXB8OkKst52BVxyW4E_H5667Sgg4aQ")
        .then((response) => response.json())
        .then((data) => {
          const fontList = data.items.map((font) => font.family);
          setFonts(fontList);
        })
        .catch((error) => console.error("Error fetching fonts:", error));
    }, []);
  
    const handleFontChange = (e) => {
      const selectedFont = e.target.value;
      setFontFamily(selectedFont);
      WebFont.load({
        google: {
          families: [selectedFont],
        },
      });

      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const selectedText = range.toString();
        if (selectedText) {
        const span = document.createElement("span");
        span.style.fontFamily = selectedFont;
        span.textContent = selectedText;
        range.deleteContents()
        range.insertNode(span);
        range.collapse(false);
        }
      }
    };
  
    const handleFontSizeChange = (e) => {
      const size = parseInt(e.target.value, 10);
      setFontSize(size);

      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const selectedText = range.toString();
        if (selectedText) {
          const span = document.createElement("span");
          span.style.fontSize = `${size}px`;
          span.textContent = selectedText;
          range.deleteContents();
          range.insertNode(span);
          range.collapse(false);
        }
      }
    };
  
    const filteredFonts = fonts.filter((font) =>
      font.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    return {
      fontFamily,
      fontSize,
      searchTerm,
      setSearchTerm,
      filteredFonts,
      handleFontChange,
      handleFontSizeChange,
    };
  };
