import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import MainLayout from './layouts/MainLayout';
import EditorLayout from './layouts/EditorLayout';
import LoginView from './components/LoginView';
import SignupView from './components/SignUpView';
import HomeView from './components/HomeView';
import PasswordReset from './components/PasswordReset';
import PasswordConfirmationCode from './components/PasswordConfirmationCode';
import PasswordNew from './components/PasswordNew';

const App = () => {
  return (
    <Router>
      <AnimatePresence mode="wait">
        <Routes>
          <Route path="/education" element={<AnimatedZoomIn><HomeView /></AnimatedZoomIn>} />
          <Route path="/signup" element={<AnimatedZoomIn><SignupView /></AnimatedZoomIn>} />
          <Route path="/login" element={<AnimatedZoomIn><LoginView /></AnimatedZoomIn>} />
          <Route path="/" element={<AnimatedZoomIn><LoginView /></AnimatedZoomIn>} />
          <Route path="/docs" element={<AnimatedZoomIn><MainLayout bool={true}/></AnimatedZoomIn>} />
          <Route path="/password-reset" element={<AnimatedZoomIn><PasswordReset /></AnimatedZoomIn>} />
          <Route path="/password-reset-confirmation" element={<AnimatedZoomIn><PasswordConfirmationCode /></AnimatedZoomIn>} />
          <Route path="/password-new" element={<AnimatedZoomIn><PasswordNew /></AnimatedZoomIn>} />
          <Route path='/editor' element={<AnimatedZoomIn><EditorLayout /></AnimatedZoomIn>}></Route>
        </Routes>
      </AnimatePresence>
    </Router>
  );
};

// Анимация при открытии страницы
const AnimatedZoomIn = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.9, filter: 'blur(10px)' }}
    animate={{ opacity: 1, scale: 1, filter: 'blur(0px)' }}
    exit={{ opacity: 0, scale: 0.9, filter: 'blur(10px)' }}
    transition={{ 
      duration: 0.7,
      ease: [0.4, 0, 0.2, 1]
    }} 
  >
    {children}
  </motion.div>
);

export default App;