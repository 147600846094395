import React from 'react';
import { useNavigate } from 'react-router-dom';
import homeImage from '../assets/images/home.png';

const HomeView = () => {
    const navigate = useNavigate();

    const navigateToDocs = () => {
        navigate('/docs');
    };

    return (
        <div
            // className="d-flex h-100 align-center justify-center"
            className='home-view-container'
            onClick={navigateToDocs}
            style={{ cursor: 'pointer' }}
        >
            <img src={homeImage} alt="" style={{ maxWidth: '100%' }} />
        </div>
    );
};

export default HomeView;
