import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../scss/custom/auth.scss";
import { ReactComponent as IconLogo } from "../assets/icons/iconLogo.svg";
import Button from "./basic/Button";
import { ReactComponent as IconPasswordShow } from "../assets/icons/iconPasswordShow.svg";

const PasswordNew = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();

  const handleNewPassword = (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      // Заглушка для сохранения нового пароля
      console.log("Новый пароль установлен!");
      // Навигация на страницу входа после успешного обновления пароля
      navigate('/login');
    } else {
      console.log("Пароли не совпадают!");
    }
  };

  return (
    <section className="auth">
      <div className="auth__container">
        {/* Логотип */}
        <IconLogo className="auth__logo" />

        <div className="auth__content-wrapper">
          <div className="auth__title-wrapper">
            <h1>Восстановление пароля</h1>
          </div>

          {/* Форма нового пароля */}
          <form className="auth__form" onSubmit={handleNewPassword}>
            {/* Новый пароль */}
            <label>
              Введите новый пароль
              <div className="auth__input-wrapper">
                <input
                  type={showPass ? "text" : "password"}
                  name="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Введите новый пароль"
                  className="auth__input auth__input--with-icon"
                  autoComplete="off"
                />
                <IconPasswordShow
                  className="auth__icon"
                  onClick={() => setShowPass(!showPass)}
                />
              </div>
            </label>

            {/* Подтверждение пароля */}
            <label>
              Подтвердите пароль
              <div className="auth__input-wrapper">
                <input
                  type={showPass ? "text" : "password"}
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Подтвердите пароль"
                  className="auth__input auth__input--with-icon"
                  autoComplete="off"
                />
                <IconPasswordShow
                  className="auth__icon"
                  onClick={() => setShowPass(!showPass)}
                />
              </div>
            </label>

            {/* Кнопка сохранить новый пароль */}
            <Button type="submit" modifier={' button--primary mt-10'}>
              Сохранить
            </Button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default PasswordNew;