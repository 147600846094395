import React from 'react';
import Button from "../../basic/Button";
import iconBold from '../../../assets/icons/iconBold.svg';
import iconItalic from '../../../assets/icons/iconItalic.svg';
import iconUnderline from '../../../assets/icons/iconUnderline.svg';

const TextFormattingButtons = ({ fontWeight, fontStyle, textDecoration, onBoldClick, onItalicClick, onUnderlineClick }) => {
return (
    <ul className="aside__container">
    <li>
        <Button modifier={fontWeight === 400 ? ' button--icon' : ' button--icon active'} onClick={onBoldClick}>
        <img src={iconBold} alt="Bold" />
        </Button>
    </li>
    <li>
        <Button modifier={fontStyle === "normal" ? ' button--icon' : ' button--icon active'} onClick={onItalicClick}>
        <img src={iconItalic} alt="Italic" />
        </Button>
    </li>
    <li>
        <Button modifier={textDecoration === "none" ? ' button--icon' : ' button--icon active'} onClick={onUnderlineClick}>
        <img src={iconUnderline} alt="Underline" />
        </Button>
    </li>
    </ul>
);
};
export default TextFormattingButtons;
