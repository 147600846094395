import { ReactComponent as SearchIcon } from "../../assets/icons/iconSearch.svg";
import "../../scss/custom/Search.scss";

const Search = ({ placeholder }) => {
    return (
        <div className="search">
          <SearchIcon className="search__icon" />
          <input
            type="text"
            className="search__input"
            placeholder={ placeholder }
          />
        </div>
    )
}

export default Search