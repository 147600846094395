import '../scss/custom/BigDoc.scss';
import docimg from '../assets/images/doc.png';
import iconuser from '../assets/icons/iconUser.svg'

const BigDoc = () => {
  return (
    <li className="bigdoc">
      <img src={docimg} alt="Document icon" className='bigdoc__preview'/>
      <p>Проект 1</p>
      <p><span><img src={iconuser} alt="user icon" /></span> <span className='bigdoc__lastchange'>3 месяца назад</span></p>
    </li>
  );
};

export default BigDoc;