import "../scss/custom/SmallDoc.scss";
import docimg from "../assets/images/doc.png";
import iconuser from "../assets/icons/iconUser.svg";

const SmallDoc = () => {
  return (
    <li className="smalldoc">
      <div className="smalldoc__preview">
        <img src={docimg} alt="Document icon"  />
        <p>Документ 1</p>
      </div>

      <p>
        10 минут назад
      </p>

      <p>
        3 месяца назад
      </p>

        <span>
          <img src={iconuser} alt="user icon" />
        </span>{" "}
    </li>
  );
};

export default SmallDoc;
