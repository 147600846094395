import { ReactComponent as ListIcon } from "./../assets/icons/iconList.svg";

import "../scss/custom/Sidebar.scss";

const Sidebar = (bool) => {
  return (
    <aside className="aside">
      {bool ? (
        <>
          <div className="aside__user">
            <div className="aside__user--avatar"></div>
            <p>Имя</p>
            <ListIcon className="aside__list--icon" />
          </div>
          {/* <div className="aside__popup">
            <div className="aside__userinfo">
              <div className="aside__user--avatar"></div>
              <p>Имя</p>
              <p>example@mail.ru</p>
            </div>

            <ul className="aside__list">
              <li>Тема</li>
              <li>Настройки</li>
              <li>Добавить аккаунт</li>
              <li>Выйти</li>
            </ul>
          </div> */}
          <ul className="aside__recent">
            <li>Последние</li>
            <li>Последние</li>
          </ul>

          <details className="aside__favorites">
            <summary className="aside__favorites--summary">Избранное</summary>
            <ul className="aside__favorites--list">
                <li>Избранное 1</li>
                <li>Избранное 1</li>
                <li>Избранное 1</li>
                <li>Избранное 1</li>
                <li>Избранное 1</li>
            </ul>
          </details>

          <details className="aside__others">
            <summary className="aside__others--summary">Другие документы</summary>
            <ul className="aside__others--list">
                <li>Документ 1</li>
                <li>Документ 1</li>
                <li>Документ 1</li>
                <li>Документ 1</li>
                <li>Документ 1</li>
            </ul>
          </details>
        </>
      ) : (
        <p></p>
      )}
    </aside>
  );
};

export default Sidebar;
